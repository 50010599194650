<template>
    <v-card>
        <v-card-text class="grey lighten-4" elevation="2">
            <v-col cols="12" sm="12">
                <v-list two-line>
                    <v-subheader>
                        <b>Historial</b>
                        <v-spacer />
                    </v-subheader>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <div class="d-flex justify-space-between pa-2">
                            <v-col cols="6" sm="6">
                                <v-autocomplete
                                    v-model="idEstado"
                                    item-text="description"
                                    item-value="id"
                                    :items="listaEstados"
                                    label="Estados"
                                    :rules="[(v) => !!v || 'Item is required']"
                                    required
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="6" sm="3">
                                <v-btn :loading="loadingEstado" @click="updateEstado(idEstado)" color="indigo" dark> Agregar </v-btn>
                            </v-col>
                        </div>
                    </v-form>
                    <v-divider> </v-divider>
                    <v-divider></v-divider>
                    <v-divider></v-divider>
                    <v-data-table multi-sort :items="historialEstado" :headers="headers" class="elevation-1" :items-per-page="20">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-icon color="primary" small @click="openModal(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-list>
            </v-col>
        </v-card-text>
        <v-dialog v-model="openDialog" max-width="400">
            <v-card>
                <v-card-title class="text-h5">CONFIRMAR</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="openDialog = false"> Cancelar </v-btn>
                    <v-btn color="green darken-1" text @click="deleteEstado"> Confirmar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { mixins } from '@/mixins/mixin.js'

export default {
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            valid: true,
            idEstado: null,
            loadingEstado: false,
            openDialog: false,
            selectedRepart: {},
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Estado', value: 'estado' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Usuario', value: 'user' },

            ]
        }
    },
    computed: {
        ...mapState('ventas', ['productos', 'idVenta']),
        ...mapState('inventario', ['historialEstado', 'listaEstados'])
    },
    async created() {
        await this.$store.dispatch('inventario/estados')
        await this.$store.dispatch('inventario/getHistorialEstado', this.idVenta)
    },
    methods: {
        openModal(payload) {
            console.log('payload ', payload)
            this.openDialog = true
            this.selectedRepart = payload
        },
        async deleteEstado() {
            await this.$store.dispatch('inventario/deleteEstado', this.selectedRepart.id)
            await this.$store.dispatch('inventario/getHistorialEstado', this.idVenta)
            this.openDialog = false
        },
        async updateEstado(item) {
            console.log('here', item)
            if (item) {
                this.$refs.form.validate()
                //console.log('this,datos', this.idVenta)
                //console.log('items', item)
                this.loadingEstado = true
                await Promise.all([this.$store.dispatch('inventario/updateEstado', { idEstado: this.idEstado, idVenta: this.idVenta })])
                await this.$store.dispatch('inventario/getHistorialEstado', this.idVenta)

                this.idEstado = null
                //console.log('historial')
                this.$store.dispatch('ventas/getReservas', {})
                this.loadingEstado = false
            }
        }
    }
}
</script>
